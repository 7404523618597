<template>
  <div id="projects" class="projects p-3 pt-lg-5 p-lg-4 container-fluid" data-bs-target="#projects">
    <h1 class="title_projects fw-bold max-auto">PROJECTS</h1>
    <div class="row pt-3 pt-lg-5 pb-3 pb-lg-5">
      <!-- WORK PROJECTS -->
      <div class="col-sm">
        <h1 class="pb-3 pb-lg-5 pt-5 pt-lg-0 fw-bold">WORK</h1>
        <div class="accordion" id="accordionJob">
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingJAut">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseJAut" aria-expanded="true" aria-controls="collapseJAut">
                <strong>Junior Automation Engineer</strong>
              </button>
            </h2>
            <div id="collapseJAut" class="accordion-collapse collapse" aria-labelledby="headingJAut"
              data-bs-parent="#accordionJob">
              <div class="accordion-body text-start">
                <p class="">
                  While in my last two semesters of my bachelor's, I chose to switch to a part-time study model. Thereby, working at Autcomp Automation AG in Stansstad as a junior automation engineer to support the team in their pursuit. There, I received the chance to work on the renewal of the ventilation system of the Gotthard Road Tunnel. Where my main task was to multiply and extend the written code in Siemens TIA and adapt several concepts from a given project documentation. Furthermore, I was involved in various other projects and also in creating schemes for them. A skill which I could take over from my apprenticeship.
                </p>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingELP">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseELP" aria-expanded="true" aria-controls="collapseELP">
                <strong>Electrical Planning</strong>
              </button>
            </h2>
            <div id="collapseELP" class="accordion-collapse collapse" aria-labelledby="headingELP"
              data-bs-parent="#accordionJob">
              <div class="accordion-body text-start">
                <p class="">
                  As an electrical planner, my job was it to create electrical plans, schemes, and tenders for the electricians on the construction site. In my two-year period after the apprenticeship at Network 41 AG and in the summer jobs at HT-Infraserv AG, I've worked on several interesting projects. A few of them can be seen in the following list:
                </p>
                <a href="https://www.google.ch/maps/place/Hochstrasser+AG+Kaffeer%C3%B6sterei/@47.0488536,8.2694026,106m/data=!3m1!1e3!4m6!3m5!1s0x478ffb3911826457:0x91fc009c32b76518!8m2!3d47.0489444!4d8.2696281!16s%2Fg%2F1td1259t?entry=ttu"
                  target="_blank">Hochstrasser AG - New coffee roastery in Lucerne</a><br />
                <a href="https://www.google.ch/maps/place/Aarau/@47.3911964,8.0503103,248m/data=!3m1!1e3!4m6!3m5!1s0x47903bf01e450701:0x1f73bd9ca50dce70!8m2!3d47.3913661!4d8.0512862!16s%2Fm%2F0r3t4xh?entry=ttu"
                  target="_blank">Aarau railroad station - Renewal of the lighting</a><br />
                <a href="https://www.hp-arch.ch/werkverzeichnis?c=Neubau_Doppelturnhalle_in_Schenkon"
                  target="_blank">Village of Schenkon - New double sports hall</a><br />
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFTTS">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFTTS" aria-expanded="false" aria-controls="collapseFTTS">
                <strong>Fiber to the Street</strong>
              </button>
            </h2>
            <div id="collapseFTTS" class="accordion-collapse collapse" aria-labelledby="headingFTTS"
              data-bs-parent="#accordionJob">
              <div class="accordion-body text-start">
                <p class="">
                  Network 41 AG, the company where I made my apprenticeship and worked for two years, offers engineering services for Swisscom AG and their fiber to the street (FTTS) rollout in all of Switzerland. The strategy is to shorten the distance of the copper wire by bringing fiber optic cables to the porch of our houses. There, inside a shaft, the light signals get converted, with so-called mCANs, into an electrical copper signal. I was fortunate to receive several further trainings to work within the Swisscom project management environment. Followed by that, I then became a project manager for two small villages where this rollout took place.
                </p>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingMobile">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseMobile" aria-expanded="false" aria-controls="collapseMobile">
                <strong>Mobile Antennas</strong>
              </button>
            </h2>
            <div id="collapseMobile" class="accordion-collapse collapse" aria-labelledby="headingMobile"
              data-bs-parent="#accordionJob">
              <div class="accordion-body text-start">
                <p class="">
                  Similarly to the FTTS rollout, Network 41 AG also provides engineering services to the Swisscom AG regarding their mobile expansion. If a new location for a mobile antenna has been set, there are many technical conditions that need to be met. One of them is the electrical supply. For that reason, I was able to visit several locations in Switzerland where I had to do on-site clarifications and plan out the electrical power supply to fit the needs of these mobile antennas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- UNIVERSITY PROJECTS -->
      <div class="col-sm">
        <h1 class="pb-3 pb-lg-5 pt-5 pt-lg-0 fw-bold">UNIVERSITY</h1>
        <div class="accordion" id="accordionUniversity">
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingPREN2">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsePREN2" aria-expanded="false" aria-controls="collapsePREN2">
                <strong>Project Unit 2 - Realization: Gravel Cleaner Robot</strong>
              </button>
            </h2>
            <div id="collapsePREN2" class="accordion-collapse collapse" aria-labelledby="headingPREN2"
              data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  This project unit builds up on the previous one where the concept has been established. The goal was
                  to build and realize said concept. Realizing a concept is always harder than expected. Still, our team
                  managed to overcome all the hurdles and even won the final contest, where all 26 teams competed
                  against each other. Furthermore, it has to be noted that our team has achieved the best grade, as the
                  only team, in both project units. As before, the report (in German) can be read at the following link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1EfRihjsRAqfgzEjhbJF00olKRSYwLgvi/view?usp=drive_link'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingPREN1">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsePREN1" aria-expanded="true" aria-controls="collapsePREN1">
                <strong>Project Unit 1 - Concept: Gravel Cleaner Robot</strong>
              </button>
            </h2>
            <div id="collapsePREN1" class="accordion-collapse collapse" aria-labelledby="headingPREN1"
              data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  This mandatory project unit provided thorough knowledge of project management and teamwork.
                  Furthermore, it
                  taught their students on how to tackle a problem and find the most economic and environmentally
                  friendly solution to it. The goal of the project was to create a concept of a gravel cleaning robot.
                  The project was approached in a team of seven members which interdisciplinary consisted of the three
                  departments electrical engineering, mechanical engineering and software engineering. The report (in
                  German) can be read at the following link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/18JhXxEUv2N6iaZRuUeWObu1FcYQFXUQx/view?usp=drive_link'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingGantryApparatus">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseGantryApparatus" aria-expanded="false" aria-controls="collapseGantryApparatus">
                <strong>PLC Systems - Gantry Apparatus</strong>
              </button>
            </h2>
            <div id="collapseGantryApparatus" class="accordion-collapse collapse"
              aria-labelledby="headingGantryApparatus" data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  This voluntary unit has been done in my semester abroad in Australia. It teaches their students to
                  independently solve automation problems and learn new technologies and methods like ladder logic LAD,
                  STL, FBD, timers, counters, digital and analog I/O, PID control, state machines and many
                  more. In this unit, three projects were due. The second project was given by our professors. It
                  consists of a
                  given gantry apparatus that had to fulfill a certain task. The report (in English) can be read at the
                  following link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1Kc4IYmSTDgHl72YuzYovUBOQalbs3SKL/view?usp=sharing'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFunctionGenerator">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFunctionGenerator" aria-expanded="false"
                aria-controls="collapseFunctionGenerator">
                <strong>PLC Systems - Function Generator</strong>
              </button>
            </h2>
            <div id="collapseFunctionGenerator" class="accordion-collapse collapse"
              aria-labelledby="headingFunctionGenerator" data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  The third project of PLC Systems was open for ideas and my team created a
                  function generator using a Siemens PLC. The report (in English) can be read at the following link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1HcmCtn82CRH5fLdMpNZm8o2Sd7WLdEeA/view?usp=drive_link'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSmoke">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseSmoke" aria-expanded="false" aria-controls="collapseSmoke">
                <strong>Electronics 2 - Smoke Detector</strong>
              </button>
            </h2>
            <div id="collapseSmoke" class="accordion-collapse collapse" aria-labelledby="headingSmoke"
              data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  This mandatory unit provides intermediate knowledge of electronic building blocks, like transistors,
                  operational amplifiers, oscillators, AD- & DA-converters, filters, noise and PCB design. There was
                  also a project due, to implement what has been learned in the unit. In a team of two, a complete and
                  working optical smoke detector has been built. The report (in German) can be read at the following
                  link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1Zyg_QEWcTSnO1Xz3YMKKiPoTNiJD66wS/view?usp=drive_link'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingRogowski">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseRogowski" aria-expanded="false" aria-controls="collapseRogowski">
                <strong>Advanced Electronics - Rogowski Coil</strong>
              </button>
            </h2>
            <div id="collapseRogowski" class="accordion-collapse collapse" aria-labelledby="headingRogowski"
              data-bs-parent="#accordionUniversity">
              <div class="accordion-body text-start">
                <p class="">
                  This voluntary unit exists to deepen the knowledge of the unit electronics two and to provide further
                  studies in the topics noise, PCB design and known problems and applications in the economy. As well as
                  in the unit electronics two, there was also a project due. This time, also in a team of two, a
                  rogowski coil has been built to measure alternating current (AC) at a frequency of 50 Hz. The report
                  (in German) can be read at the following link:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1IWTPuyA_72i_hW-cgPbCt0U-rQIFvpgV/view?usp=drive_link'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PRIVATE PROJECTS -->
      <div class="col-sm">
        <h1 class="pt-5 pt-lg-0 pb-3 pb-lg-5 fw-bold">PRIVATE</h1>
        <div class="accordion" id="accordionPrivate">
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingPortfolio">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsePortfolio" aria-expanded="true" aria-controls="collapsePortfolio">
                <strong>My Portfolio Website</strong>
              </button>
            </h2>
            <div id="collapsePortfolio" class="accordion-collapse collapse" aria-labelledby="headingPortfolio"
              data-bs-parent="#accordionPrivate">
              <div class="accordion-body text-start">
                <p class="">
                  Whilst in my studies at HSLU, I already had two units regarding the development of websites. To
                  further deepen this knowledge, I decided to create my own portfolio website. Though, this is not a
                  typical task for an electrical engineer, I thought it was a great idea to further show one of my many
                  skill sets. In the future, this website might even be completely overhauled and serve an entirely
                  different purpose.
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/14rNpaCR076suzbZmpn011jCaaD4PJECZ/view?usp=sharing'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Project Overview
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingPower">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsePower" aria-expanded="false" aria-controls="collapsePower">
                <strong>Lab Power Supply V2</strong>
              </button>
            </h2>
            <div id="collapsePower" class="accordion-collapse collapse" aria-labelledby="headingPower"
              data-bs-parent="#accordionPrivate">
              <div class="accordion-body text-start">
                <p class="">
                  In the first semester of my studies in electrical engineering, I built a basic lab power supply. It
                  has been used intensively in the testing phase of the first project unit. Realizing how handy such a
                  power supply is, I wanted the first version to be overhauled because of it lacking some features. The
                  second version was designed using Fusion 360 and 3D-printed with my own 3D-printer at home. Key
                  information, photos and circuitry can be found in the following project overview:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1q0NkdBuuj30H5NaQPJwRcDvxzHrzZf6E/view?usp=sharing'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Project Overview
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingDesk">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseDesk" aria-expanded="false" aria-controls="collapseDesk">
                <strong>Desk PC</strong>
              </button>
            </h2>
            <div id="collapseDesk" class="accordion-collapse collapse" aria-labelledby="headingDesk"
              data-bs-parent="#accordionPrivate">
              <div class="accordion-body text-start">
                <p class="">
                  Whilst in the middle of the corona time (summer 2020), I got bored and built a desk PC. The clue is,
                  that all computer hardware is completely hidden inside the desk and not visible at first glance. For
                  that reason, I had to build the desk from scratch. Furthermore, the issue of cooling had to be
                  addressed. Because of the minimal height of 9 cm inside the desk, no tower cooler could be installed.
                  Consequently, a water cooling system has been put in place. Key information, photos and circuitry can
                  be found in the following project overview:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/17V8bYgCQ2ys4mMSINYPnUa6t1I5MFiZx/view?usp=sharing'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Project Overview
                </button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingVenti">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseVenti" aria-expanded="false" aria-controls="collapseVenti">
                <strong>Portable Fan V3</strong>
              </button>
            </h2>
            <div id="collapseVenti" class="accordion-collapse collapse" aria-labelledby="headingVenti"
              data-bs-parent="#accordionPrivate">
              <div class="accordion-body text-start">
                <p class="">
                  By repurposing an old PC-Fan, I've created a simple new product that keeps me cool in hot summers. It
                  has a 5 V micro USB plug that can be powered by any phone charger. Due to the large fan size of 200
                  mm, there is hardly any noise while still delivering a fair amount of airflow. To easily place the fan
                  everywhere, a tripod of an old selfie stick has been repurposed to be the stand of this fan.
                  Therefore, the fan can be placed on all flat surfaces. Additionally, the height can be adjusted. This
                  fan is fairly simple and easy to build, but still, some thought had to be put into it to build this
                  fan. Key information, photos and circuitry can be found in the following project overview:
                </p>
                <button @click="
                  openFileInNewWindow(
                    'https://drive.google.com/file/d/1YMZoGDg2Zqp4amGJ15iv8pzoDc9Kl4Fq/view?usp=sharing'
                  )
                  " type="button" class="btn btn-primary" style="
                    background-color: #eb5e28;
                    color: white;
                    border: #eb5e28;
                  ">
                  Project Overview
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeProjects",
  props: {
    msg: String,
  },
  methods: {
    openFileInNewWindow(fileName) {
      window.open(fileName, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.projects {
  background-color: #354f52;
}

.title_projects {
  font-size: 3rem;
}

.justified-text {
  text-align: justify;
}

.p-10 {
  padding: 7rem !important;
  /* 112px */
}

.accordion-button:not(.collapsed) {
  text-emphasis-color: black;
  background-color: white;
}

.accordion-button:focus {
  box-shadow: none;
  color: white;
  background-color: #eb5e28;
}

.accordion-button:active {
  color: white;
  background-color: #eb5e28;
}

/* Media query for smartphones (up to 768px wide) */
@media (max-width: 768px) {
  .title_projects {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }
}
</style>
